// https://github.com/draft-js-plugins/draft-js-plugins/blob/master/packages/anchor/src/

import mailRegex from "./mailRegex";
import urlRegex from "./urlRegex";

const URLUtils = {
  isUrl(text: string): boolean {
    return urlRegex().test(text);
  },

  isMail(text: string): boolean {
    return mailRegex().test(text);
  },

  normalizeMail(email: string): string {
    if (email.toLowerCase().startsWith("mailto:")) {
      return email;
    }
    return `mailto:${email}`;
  },

  normalizeUrl(url: string | undefined): string | undefined {
    if (!url) {
      return url;
    }
    if (!URLUtils.isUrl(url)) {
      return url;
    }
    if (url.startsWith("http")) {
      return url;
    }
    return `https://${url}`;
  },

  safeUrl(url: string | undefined): URL | undefined {
    if (!url || !URLUtils.isUrl(url)) {
      return undefined;
    }
    try {
      return new URL(url);
    } catch (e) {
      return undefined;
    }
  },

  safeUrlWithBase(
    url: string | null | undefined,
    base: string | null | undefined,
  ): URL | undefined {
    if (!url) {
      return undefined;
    }
    try {
      return new URL(url, base || "");
    } catch (e) {
      return undefined;
    }
  },

  /** Rough check to ensure the hostname looks legit.
   *
   * We coerce values like "None" and "N/a" into a URL (https://N/a) in memo pitchDeckURL, user's companURL, etc.
   * But we don't want to link them in the UI if we're confident it won't resolve.
   *
   * OTOH we do want to accept URLs like "mynewco.com", even if they don't have the protocol.
   * We also felt that rejecting the URL in the client may be more cumbersome than just letting the value through, saving it (since it's what the user entered), but recongizing that the URL is invalid.
   */
  isActionableURL(url: string | undefined): boolean {
    return !!URLUtils.safeUrl(url)?.hostname.includes(".");
  },
};

export default URLUtils;
